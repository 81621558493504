import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { PincodeListItem } from './PincodeListItem';
import { PinCodeResponse } from '../product/interfaces';

interface Props {
    pinCodeData: PinCodeResponse[];
}

export const CheckoutDetails = ({ pinCodeData }: Props) => {
    return (
        <Box sx={{ pb: 1 }}>
            <Typography variant="h5" color="black" fontWeight="500" sx={{ mb: 1, mt: 1 }}>
                {t('translation.orderConfirmation.openingDetails')}
            </Typography>
            {pinCodeData?.map((pinData, i) => (
                <PincodeListItem key={i} pinData={pinData} />
            ))}
        </Box>
    );
};
