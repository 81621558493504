import { useCallback, useEffect, useState } from 'react';
import { Button, Typography, Box, Select, MenuItem, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { PromoCodeV2Alpha2 } from '../product/interfaces';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';

interface Props {
    promoCode: PromoCodeV2Alpha2;
    addPromo: (promoCode: PromoCodeV2Alpha2) => void;
    currencyISO: string;
}

const PromoCard = ({ promoCode, addPromo, currencyISO }: Props) => {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState(0);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const onBook = useCallback(
        (promoCode: PromoCodeV2Alpha2) => {
            const uuid = promoCode.productVariants.find((variant) => Number(variant.price) === selectedValue)?.uuid || '';
            const promoCodeWithValue = { ...promoCode, selectedProductValue: selectedValue, uuid };
            addPromo(promoCodeWithValue);
        },
        [addPromo, selectedValue]
    );

    useEffect(() => {
        if (promoCode.productVariants && promoCode.productVariants.length > 0) {
            const firstVariant = promoCode.productVariants[0];
            const defaultValue = firstVariant.price;

            if (defaultValue !== undefined) {
                setSelectedValue(Number(defaultValue));
            }
        }
    }, [promoCode]);

    return (
        <Box sx={{ display: 'flex', mb: 2 }}>
            <Box
                sx={{
                    alignSelf: 'center',
                    width: '120px',
                    height: '120px',
                    marginRight: '16px',
                    textAlign: 'center'
                }}>
                <img
                    alt="productImg"
                    src={
                        promoCode.imageUrl
                            ? promoCode.imageUrl
                            : 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/800px-No-Image-Placeholder.svg.png'
                    }
                    style={{ height: '100%', width: '100%', margin: 'auto', objectFit: 'cover' }}
                />
            </Box>
            <p>{promoCode.cost}</p>
            <Box sx={{ flex: '1' }}>
                <Typography variant="h5">{promoCode.name ? translate(promoCode.name, getCurrentLanguageFromLocalStorage()) : 'N/A'}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="caption">{promoCode.coupon ? promoCode.coupon : 'Caption'}</Typography>

                    <Tooltip title={translate(promoCode.description, getCurrentLanguageFromLocalStorage())}>
                        <InfoOutlinedIcon sx={{ fontSize: '1.85em', marginRight: '40 px' }} />
                    </Tooltip>
                </Box>
                <Box>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        {selectedValue}
                        {currencyISO}
                    </Typography>
                    <Box>
                        <Select
                            displayEmpty
                            value={selectedValue}
                            onChange={handleChange}
                            sx={{
                                borderRadius: 20,
                                maxHeight: 40,
                                border: 'solid 1px #004E31',
                                marginRight: 1
                            }}
                            MenuProps={{ sx: { zIndex: 2147483647 } }}>
                            {promoCode.type === 2 && // klippkort
                                promoCode.productVariants &&
                                promoCode.productVariants.map((variant) => (
                                    <MenuItem key={variant.uuid} value={variant.price}>
                                        {variant.worth} ggr
                                    </MenuItem>
                                ))}
                            {promoCode.type === 1 && // giftcards
                                promoCode.productVariants &&
                                promoCode.productVariants.map((variant) => (
                                    <MenuItem key={variant.uuid} value={variant.price}>
                                        {variant.price}
                                        {currencyISO}
                                    </MenuItem>
                                ))}
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                borderRadius: 20,
                                height: 40
                            }}
                            onClick={() => {
                                onBook(promoCode);
                            }}>
                            {t('translation.buyButton')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PromoCard;
