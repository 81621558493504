import { Button, Typography, Box, IconButton, TextField, Badge, Chip, Grid, FormControl, Zoom, Select, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';
import { useTranslation } from 'react-i18next';
import { BookableEventV1, EventV1 } from '../product/interfaces';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translate, useSelectedLanguage } from '../../utils/languageTranslation.util';
import { PriceGroupQuantityType } from '../booking/EventSelection';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LocalStorageUtil from '../../utils/localStorage.util';

interface Props {
    event: EventV1;
    addEvent: (
        modifications: Array<{
            eventOccation: BookableEventV1;
            quantity: number;
            eventOccationCost: number;
            vat: number;
            priceGroup?: string;
        }>
    ) => void;
    currencyISO: string;
    selectedEventOccations: BookableEventV1[];
    timeSettingAmPm: boolean;
}

enum EventAvailability {
    AVAILABLE,
    SEMI,
    FULL,
    NOTSET
}

const EventCard: React.FC<Props> = ({ event, addEvent, currencyISO, selectedEventOccations, timeSettingAmPm }) => {
    const [expanded, setExpanded] = useState(false);
    const [date, setDate] = useState<Moment | null>(null);
    const [availableCourses, setAvailableCourses] = useState<BookableEventV1[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<BookableEventV1 | null>(null);
    const [quantity, setQuantity] = useState(1);
    const [clicked, setClicked] = useState(false);
    const [priceGroupQuantity, setPriceGroupQuantity] = useState<PriceGroupQuantityType>({});
    const [currentMonth, setCurrentMonth] = useState(moment.tz());
    const { t } = useTranslation();
    const selectedLanguage = useSelectedLanguage();

    const longDescription = useMemo(() => {
        if (typeof event.longDescription === 'object') {
            return translate(event.longDescription, selectedLanguage);
        } else if (typeof event.longDescription === 'string') {
            return event.longDescription;
        }

        return '';
    }, [event.longDescription, selectedLanguage]);

    const infoText = useMemo(() => {
        if (typeof event.infoText === 'object') {
            return translate(event.infoText, selectedLanguage);
        } else if (typeof event.infoText === 'string') {
            return event.infoText;
        }

        return '';
    }, [event.infoText, selectedLanguage]);

    const fromPrice = useMemo(() => {
        if (event.priceGroup && Object.keys(event.priceGroup).length > 0) {
            const lowestPrice = Object.entries(event.priceGroup).reduce((prev, current) => {
                const prevPrice = prev[1].cost;
                const currentPrice = current[1].cost;
                return prevPrice < currentPrice ? prev : current;
            });
            return lowestPrice[1].cost;
        } else {
            return event.cost;
        }
    }, [event.priceGroup, event.cost]);

    useEffect(() => {
        if (event.bookableEvents) {
            const bookableEventsOnDay = event.bookableEvents.filter((bookableEvent) => {
                return moment(bookableEvent.startDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
            });

            setAvailableCourses(bookableEventsOnDay);

            if (bookableEventsOnDay.length === 1) {
                setSelectedCourse(bookableEventsOnDay[0]);
            }
        }
    }, [date]);

    const getBadge = (eventAvailability: EventAvailability) => {
        switch (eventAvailability) {
            case EventAvailability.AVAILABLE:
                return (
                    <div
                        style={{
                            borderRadius: '50%',
                            width: '10px',
                            height: '10px',
                            marginLeft: '-8px',
                            marginTop: '8px',
                            backgroundColor: 'green'
                        }}></div>
                );
            case EventAvailability.SEMI:
                return (
                    <div
                        style={{
                            borderRadius: '50%',
                            width: '10px',
                            height: '10px',
                            marginLeft: '-8px',
                            marginTop: '8px',
                            backgroundColor: 'orange'
                        }}></div>
                );
            case EventAvailability.FULL:
                return (
                    <div
                        style={{
                            borderRadius: '50%',
                            width: '10px',
                            height: '10px',
                            marginLeft: '-8px',
                            marginTop: '8px',
                            backgroundColor: 'red'
                        }}></div>
                );
            case EventAvailability.NOTSET:
                return <></>;
        }
    };
    const getDateAvailability = (day) => {
        let availability: EventAvailability = EventAvailability.NOTSET;

        if (event.bookableEvents) {
            const bookableEventsOnDay = event.bookableEvents.filter((bookableEvent) => {
                return moment(bookableEvent.startDate).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD');
            });

            bookableEventsOnDay.every((eventOccation) => {
                const selectedOccation = selectedEventOccations.find((selectedEventOccation) => {
                    return selectedEventOccation.id === eventOccation.id;
                });
                const eventPercentage = (eventOccation.bookedAttendees + (selectedOccation?.quantity ?? 0)) / event.inventory;
                if (eventPercentage >= 1) {
                    // full
                    if (availability !== EventAvailability.SEMI && availability !== EventAvailability.AVAILABLE) {
                        availability = EventAvailability.FULL;
                    }
                } else if (eventPercentage < 1 && eventPercentage >= 0.8) {
                    // semi-full
                    if (availability !== EventAvailability.AVAILABLE) {
                        availability = EventAvailability.SEMI;
                    }
                } else {
                    availability = EventAvailability.AVAILABLE;
                    return false;
                }

                return true;
            });
        }

        return availability as EventAvailability;
    };

    const onBook = useCallback(
        (bookedEvent: BookableEventV1, quantity: number) => {
            setClicked(true);

            const modifications: Array<{
                eventOccation: BookableEventV1;
                quantity: number;
                eventOccationCost: number;
                vat: number;
                priceGroup?: string;
            }> = [];

            if (event.priceGroup && Object.keys(event.priceGroup).length > 0) {
                Object.entries(priceGroupQuantity).forEach(([key, value]) => {
                    if (value > 0 && event.priceGroup) {
                        const priceGroupItem = event.priceGroup[key];
                        modifications.push({
                            eventOccation: bookedEvent,
                            quantity: value,
                            eventOccationCost: priceGroupItem.cost,
                            vat: event.vat,
                            priceGroup: key
                        });
                    }
                });
            } else {
                modifications.push({
                    eventOccation: bookedEvent,
                    quantity: quantity,
                    eventOccationCost: event.cost,
                    vat: event.vat
                });
            }

            addEvent(modifications);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [event.cost, event.priceGroup, event.vat, priceGroupQuantity]
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (clicked) {
                setClicked(false);
            }
        }, 2000);

        return () => clearTimeout(timeout);
    }, [clicked]);

    const itemsLeftInStock = useMemo(() => {
        if (selectedCourse) {
            const selectedOccation = selectedEventOccations.find((selectedEventOccation) => {
                return selectedEventOccation.id === selectedCourse.id;
            });
            return (selectedCourse?.attendees ?? 0) - (selectedOccation?.quantity ?? 0);
        }

        return 0;
    }, [selectedCourse, selectedEventOccations]);

    const getCourseOccationAvailableSpots = (course) => {
        if (course) {
            let totalOccationQuantity = 0;
            selectedEventOccations.forEach((selectedEventOccation) => {
                if (selectedEventOccation.id === course.id) {
                    totalOccationQuantity += selectedEventOccation.quantity;
                }
            });
            // const selectedOccation = selectedEventOccations.find((selectedEventOccation) => {
            //     return selectedEventOccation.id === course.id;
            // });
            return (course?.attendees ?? 0) - (totalOccationQuantity ?? 0);
        }

        return 0;
    };

    const getAvailablePriceGroupQuantity = (priceGroupKey: string) => {
        const availableCourseSpots = getCourseOccationAvailableSpots(selectedCourse);
        let selectedQuantity = 0;
        Object.entries(priceGroupQuantity).forEach(([key, value]) => {
            if (key !== priceGroupKey) {
                selectedQuantity += value;
            }
        });

        return availableCourseSpots - selectedQuantity;
    };

    const availabilityDay = (pickersDayProps: PickersDayProps<Moment>) => {
        const { day, outsideCurrentMonth, ...otherDayProps } = pickersDayProps;

        if (!day) {
            return <PickersDay {...pickersDayProps} outsideCurrentMonth={outsideCurrentMonth} {...otherDayProps} day={day} />;
        }

        if (day.month() !== currentMonth.month()) {
            return <PickersDay {...pickersDayProps} outsideCurrentMonth={outsideCurrentMonth} {...otherDayProps} day={day} />;
        }

        const dayValue = day ?? moment();
        const availability = getDateAvailability(dayValue);

        const badge = otherDayProps.disabled === false ? getBadge(availability) : null;

        return (
            // <Badge key={dayValue.toString()} overlap="circular" badgeContent={getBadge(availability)}>
            //     <PickersDay {...pickersDayProps} disabled={availability === EventAvailability.FULL || availability === EventAvailability.NOTSET} />
            // </Badge>

            <React.Fragment>
                <PickersDay
                    {...otherDayProps}
                    outsideCurrentMonth={outsideCurrentMonth}
                    day={day}
                    disabled={availability === EventAvailability.FULL || availability === EventAvailability.NOTSET}
                />
                {badge && <Badge overlap="circular" badgeContent={badge} />}
            </React.Fragment>
        );
    };

    const selectedLanguageValue = LocalStorageUtil.getItem('user-language-selected') || 'en';

    useEffect(() => {
        if (selectedLanguageValue !== 'en') {
            import(`moment/locale/${selectedLanguageValue}`)
                .then(() => {
                    moment.locale(selectedLanguageValue);
                })
                .catch((error) => console.error(`Could not load moment locale for ${selectedLanguageValue}`, error));
        } else {
            moment.locale('en');
        }
    }, [selectedLanguageValue]);

    moment.updateLocale(selectedLanguageValue, {
        week: {
            dow: 1
        }
    });

    return (
        <Box sx={{ display: 'flex', mb: 5, ...(expanded ? { flexWrap: 'wrap' } : {}) }}>
            <Box
                sx={{
                    alignSelf: 'center',
                    width: '120px',
                    marginRight: '16px',
                    textAlign: 'center'
                }}>
                <img alt="product" src={event.productImageUrl} style={{ maxHeight: 120, maxWidth: 120, margin: 'auto' }} />
            </Box>

            <Box sx={{ flex: '1' }}>
                <Typography variant="h5">{translate(event.name, selectedLanguage)}</Typography>
                <Typography variant="caption">{translate(event.shortDescription, selectedLanguage)}</Typography>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    {event.priceGroup && Object.keys(event.priceGroup).length > 0 && <>{t('translation.eventSelection.priceFrom')}&nbsp;</>}
                    {fromPrice}
                    {currencyISO}
                </Typography>
                {!expanded && (
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<CalendarMonthIcon />}
                        sx={{
                            borderRadius: 20,
                            height: 40,
                            px: [2.5, 4]
                        }}
                        onClick={() => {
                            setExpanded(true);
                        }}
                        disabled={event.bookableEvents === undefined || event.bookableEvents.length <= 0}>
                        {t('translation.eventSelection.selectDateButton')}
                    </Button>
                )}
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                <Box sx={{ width: '100%', paddingTop: '8px' }}>
                    <Typography variant="caption">
                        {longDescription.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </Typography>
                    <Box sx={{ textAlign: 'center', marginTop: '16px', position: 'relative' }}>
                        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                            {t('translation.eventSelection.selectDate')}
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: '-16px' }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StaticDatePicker
                                displayStaticWrapperAs="desktop"
                                value={date}
                                views={['day']}
                                disableHighlightToday
                                onMonthChange={(newMonth) => {
                                    const momentNewMonth = moment(newMonth.toString());
                                    setCurrentMonth(momentNewMonth);
                                }}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                    setSelectedCourse(null);
                                }}
                                slots={{
                                    toolbar: () => null,
                                    day: (pickersDayProps) => availabilityDay(pickersDayProps)
                                }}
                                slotProps={{
                                    actionBar: { actions: [] }
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    {availableCourses.length > 0 && (
                        <>
                            <Box sx={{ marginTop: '-16px' }}>
                                <Typography variant="subtitle1" gutterBottom color="primary" sx={{ mb: 0 }}>
                                    {t('translation.eventSelection.selectHour')}
                                </Typography>
                                <Grid sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                    {availableCourses.map((course, index) => (
                                        <Chip
                                            onClick={() => {
                                                const sameEventInCart = selectedEventOccations.find(
                                                    (occation) => occation.eventId === course.eventId
                                                );
                                                course.info = sameEventInCart?.info;
                                                setSelectedCourse(course);
                                            }}
                                            key={index}
                                            disabled={course.attendees <= 0 || getCourseOccationAvailableSpots(course) <= 0}
                                            label={moment(course.startDate).format(timeSettingAmPm ? 'hh:mm A' : 'HH:mm')}
                                            color="primary"
                                            size="small"
                                            variant={selectedCourse?.id === course.id ? 'filled' : 'outlined'}
                                            sx={
                                                timeSettingAmPm
                                                    ? {
                                                          width: '65px',
                                                          height: '26px',
                                                          '& .MuiChip-label': {
                                                              fontSize: '10px'
                                                          }
                                                      }
                                                    : {
                                                          width: '52px',
                                                          height: '26px',
                                                          '& .MuiChip-label': {
                                                              fontSize: '11px'
                                                          }
                                                      }
                                            }
                                        />
                                    ))}
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Typography variant="subtitle1">
                                    <b>{translate(event.name, selectedLanguage)}</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    <b>{t('translation.eventSelection.start')} </b>
                                    {selectedCourse ? moment(selectedCourse?.startDate).format(timeSettingAmPm ? 'hh:mm A' : 'HH:mm') : ''}
                                </Typography>
                                <Typography variant="subtitle1">
                                    <b>{t('translation.eventSelection.end')} </b>
                                    {selectedCourse ? moment(selectedCourse?.endDate).format(timeSettingAmPm ? 'hh:mm A' : 'HH:mm') : ''}
                                </Typography>

                                {/* ToDo: we are missing a BE mapping to the event string */}
                                {false && <TextField fullWidth multiline placeholder={'!!!Fyll i lägngd på deltagare!!!'}></TextField>}
                            </Box>
                        </>
                    )}

                    {infoText && (
                        <Box>
                            <TextField
                                multiline={true}
                                placeholder={infoText}
                                sx={{ width: '100%' }}
                                rows={2}
                                disabled={selectedCourse === null}
                                value={selectedCourse?.info}
                                onChange={(event) => {
                                    if (selectedCourse) {
                                        const newSelected = { ...selectedCourse };
                                        newSelected.info = event.target.value;
                                        // setSelectedCourse({ ...selectedCourse, info: event.target.value });
                                        setSelectedCourse(newSelected);
                                    }
                                }}
                            />
                        </Box>
                    )}
                    <Box style={{ marginTop: '8px' }}>
                        {event.priceGroup && Object.keys(event.priceGroup).length > 0 ? (
                            <>
                                {Object.entries(event.priceGroup).map(([key, priceItem]) => {
                                    return (
                                        <div
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                gap: '16px',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                                maxWidth: '350px',
                                                marginBottom: '8px'
                                            }}>
                                            <Typography variant="h4" style={{ flex: 1 }}>
                                                {priceItem[selectedLanguage] ?? priceItem.en}
                                            </Typography>
                                            <Typography variant="h4">
                                                {priceItem.cost}
                                                {currencyISO}
                                            </Typography>
                                            <FormControl sx={{ minWidth: 65 }}>
                                                <Select
                                                    value={itemsLeftInStock ? priceGroupQuantity[key] ?? 0 : 0}
                                                    disabled={selectedCourse === null || itemsLeftInStock <= 0}
                                                    onChange={(event) => {
                                                        const qty = event.target.value as number;
                                                        setPriceGroupQuantity({
                                                            ...priceGroupQuantity,
                                                            [key]: qty
                                                        });
                                                    }}
                                                    sx={{
                                                        borderRadius: 20,
                                                        maxHeight: 40,
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: itemsLeftInStock ? 'solid 1px #004E31' : 'solid 1px rgba(0, 0, 0, 0.12)'
                                                        }
                                                    }}
                                                    MenuProps={{ sx: { zIndex: 2147483647 } }}>
                                                    {[...Array(getAvailablePriceGroupQuantity(key) + 1).keys()].map((index) => (
                                                        <MenuItem key={index} value={index}>
                                                            {index}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Typography variant="h4">
                                    {event.cost}
                                    {currencyISO}
                                </Typography>
                                <FormControl sx={{ minWidth: 65 }}>
                                    <Select
                                        value={itemsLeftInStock ? quantity : ''}
                                        disabled={selectedCourse === null || itemsLeftInStock <= 0}
                                        onChange={(event) => {
                                            const qty = event.target.value as number;
                                            setQuantity(qty);
                                        }}
                                        sx={{
                                            borderRadius: 20,
                                            maxHeight: 40,
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: itemsLeftInStock ? 'solid 1px #004E31' : 'solid 1px rgba(0, 0, 0, 0.12)'
                                            }
                                        }}
                                        MenuProps={{ sx: { zIndex: 2147483647 } }}>
                                        {[...Array(itemsLeftInStock).keys()].map((key) => (
                                            <MenuItem key={key + 1} value={key + 1}>
                                                {key + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}

                        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    borderRadius: 20,
                                    height: 40,
                                    px: [2.5, 4]
                                }}
                                onClick={() => {
                                    if (selectedCourse !== null) {
                                        onBook(selectedCourse, quantity);
                                        // setSelectedCourse(null);
                                        setQuantity(1);
                                        setPriceGroupQuantity({});
                                    }
                                }}
                                style={{ flex: 1 }}
                                disabled={selectedCourse === null || itemsLeftInStock <= 0}>
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    clicked ? (
                                        <Zoom in={clicked}>
                                            <CheckIcon />
                                        </Zoom>
                                    ) : itemsLeftInStock <= 0 && selectedCourse !== null ? (
                                        t('translation.productSelection.fullyBooked')
                                    ) : (
                                        t('translation.productSelection.bookbutton')
                                    )
                                }
                            </Button>
                            <IconButton
                                aria-label="expand less"
                                onClick={() => {
                                    setExpanded(false);
                                }}>
                                <ExpandLessIcon sx={{ fontSize: '34px' }} />
                            </IconButton>
                        </div>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

export default EventCard;
