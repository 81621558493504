import { useEffect, useMemo, useState } from 'react';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Skeleton, useMediaQuery } from '@mui/material';
import { FormsResponse, FreeTextQuestionItem, ImageItem, QuestionItem, RatingQuestionItem } from './interface';
import FreeText from './components/FreeText';
import RatingQuestion from './components/RatingQuestion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import GoogleReviews from './components/GoogleReviews';
import './ReturnStyles.scss';
import ImageUpload from './components/ImageUpload';

interface Props {
    bookingId: string;
    siteId: string;
}

export default function ReturnPage({ bookingId, siteId }: Props) {
    const [isSendAnswersLoading, setIsSendAnswersLoading] = useState(false);
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(true);
    const [isReturned, setIsReturned] = useState(false);
    const [formData, setFormData] = useState<FormsResponse | null>(null);
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');

    async function getFormData() {
        try {
            const response = await axios.get<FormsResponse>(`sites/v1/questionnaire/${siteId}/${bookingId}`);

            if (response.status === 200) {
                const data = await response.data;
                setFormData(data);
                if (data.submitted) {
                    setIsReturned(true);
                }

                if (data.questions.length === 0) {
                    returnOrder();
                } else {
                    setIsQuestionsLoading(false);
                }
            }
        } catch (error) {
            console.error("Couldn't get form data");
        }
    }

    async function returnOrder() {
        try {
            const response = await axios.get(`hardware/locker/return/${bookingId}`);
            if (response.status === 200) {
                setIsReturned(true);
                setIsQuestionsLoading(false);
            } else {
                console.error('Something went wrong');
            }
        } catch (error) {
            console.error('Something went wrong');
        }
    }

    async function sendAnswers() {
        try {
            const objecToSend = {
                questionsAndAnswers: formData?.questions
            };

            const response = await axios.post(`booking/v1/review/${bookingId}`, objecToSend);
            if (response.status === 200) {
                returnOrder();
            }
        } catch (error) {
            setIsSendAnswersLoading(false);
            console.error("Couldn't send answers");
        }
    }

    useEffect(() => {
        if (siteId) {
            getFormData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    const getQuestionComponent = (question: QuestionItem, index: number) => {
        switch (question.type) {
            case 'freeText':
                return (
                    <FreeText
                        question={question}
                        index={index}
                        updateQuestion={(index: number, answer: string) => {
                            if (formData && formData.questions) {
                                const newFormData = { ...formData };
                                const question = newFormData.questions[index] as FreeTextQuestionItem;
                                question.answer = answer;
                                setFormData(newFormData);
                            }
                        }}
                    />
                );
            case 'rating':
                return (
                    <RatingQuestion
                        question={question}
                        index={index}
                        updateQuestion={(index: number, answer: number) => {
                            if (formData && formData.questions) {
                                const newFormData = { ...formData };
                                const question = newFormData.questions[index] as RatingQuestionItem;
                                question.answer = answer;
                                setFormData(newFormData);
                            }
                        }}
                    />
                );
            case 'photo':
                return (
                    <ImageUpload
                        question={question}
                        bookingId={bookingId}
                        updateQuestion={(answer: string) => {
                            if (formData && formData.questions) {
                                const newFormData = { ...formData };
                                const question = newFormData.questions[index] as ImageItem;
                                question.answer = answer;
                                setFormData(newFormData);
                            }
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const isAnyQuestionAnswerInvalid = useMemo(() => {
        if (formData?.questions) {
            return formData.questions.some((question) => {
                if (question.mandatory && !question.answer) {
                    return true;
                }
                return false;
            });
        }

        return false;
    }, [formData]);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Return Page'
            });
        }
    }, []);

    return (
        <Box className="returnPage-box">
            <h2>{t('translation.return.thankYou')}</h2>

            <Box
                sx={{
                    padding: isMobile ? '16px' : '24px',
                    textAlign: 'left',
                    backgroundColor: '#F6F6F6',
                    borderRadius: '16px',
                    width: '100%'
                }}>
                {isQuestionsLoading ? (
                    <>
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                    </>
                ) : isReturned ? (
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <CheckCircleIcon sx={{ color: '#00C853', fontSize: '40px' }} />
                        <h4>{t('translation.return.thankYouShort')}</h4>
                    </div>
                ) : (
                    <>
                        {formData?.questions.map((question, index) => {
                            return (
                                <div key={index} style={{ marginBottom: '16px' }}>
                                    {getQuestionComponent(question, index)}
                                </div>
                            );
                        })}

                        <div style={{ textAlign: 'center', marginTop: '24px' }}>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: '20px' }}
                                disabled={isAnyQuestionAnswerInvalid || isSendAnswersLoading}
                                onClick={() => {
                                    setIsSendAnswersLoading(true);
                                    sendAnswers();
                                }}>
                                {t('translation.return.completeButton')}
                                {isSendAnswersLoading && (
                                    <CircularProgress
                                        size={30}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-16px',
                                            marginLeft: '-16px'
                                        }}
                                    />
                                )}
                            </Button>
                        </div>
                    </>
                )}
            </Box>
            <div
                dangerouslySetInnerHTML={{
                    __html: translate(formData?.returnMessage ?? '', getCurrentLanguageFromLocalStorage())
                }}
                style={{ marginBottom: '24px', marginTop: '24px' }}></div>
            {formData?.placeId && <GoogleReviews placeId={formData?.placeId} reviewUrl={formData?.reviewLink} />}
        </Box>
    );
}
