import { Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ImageItem } from '../interface';
import { getCurrentLanguageFromLocalStorage, translate } from '../../../utils/languageTranslation.util';
import { useTranslation } from 'react-i18next';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import axiosInstance from '../../../utils/axios';

interface Props {
    question: ImageItem;
    bookingId: string;
    updateQuestion: (answer: string) => void;
}

const uploadImageToSignedUrl = async (signedUrl: string, file: File) => {
    try {
        const response = await fetch(signedUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type
            },
            body: file
        });

        if (!response.ok) {
            throw new Error('Failed to upload image');
        }
    } catch (error) {
        console.error('Error when uploading:', error);
    }
};

const ImageUpload: React.FC<Props> = ({ question, bookingId, updateQuestion }) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [base64Image, setBase64Image] = useState<string | null>(null);
    const [imageFileName, setImageFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [filePath, setFilePath] = useState('');

    useEffect(() => {
        if (filePath) {
            updateQuestion(filePath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filePath]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            setImageFileName(file.name);
            setSelectedFile(file);
            reader.onloadend = () => {
                setBase64Image(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const uploadImage = async (imageId: number, filename: string): Promise<string> => {
        try {
            const response = await axiosInstance.get(`sites/v1/questionnaire/upload/${bookingId}/after/${imageId}/${filename}`);
            if (response.status === 200) {
                const matches = base64Image?.match(/^data:(.*);base64,(.*)$/);
                if (!matches || matches.length !== 3) {
                    throw new Error('Invalid base64 image format');
                }

                const signedUrl = response.data.url;
                await uploadImageToSignedUrl(signedUrl, selectedFile as File);

                return response.data.filePath as string;
            } else {
                throw new Error('Something went wrong when uploading image');
            }
        } catch (error) {
            console.error('Error when uploading:', error);
        }

        return '';
    };

    useEffect(() => {
        const uploadAndSetFilePath = async () => {
            if (base64Image && imageFileName) {
                const uploadFilePath = await uploadImage(question.imageId, imageFileName);
                setFilePath(uploadFilePath);
            }
        };

        uploadAndSetFilePath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64Image, question.imageId, imageFileName]);

    return (
        <div className="img-upload-container">
            <div className="img-upload-container__left-column">
                <div style={{ marginBottom: '4px' }}>
                    {translate(question.question, getCurrentLanguageFromLocalStorage())}
                    {question.mandatory && <> *</>}
                </div>
                <input ref={inputRef} type="file" accept="image/*" capture="environment" onChange={handleFileChange} style={{ display: 'none' }} />
                <Button
                    variant="contained"
                    endIcon={<CameraAltRoundedIcon />}
                    className="img-upload-container__button"
                    onClick={() => inputRef.current?.click()}
                    fullWidth>
                    {base64Image ? t('translation.beforeUnlock.changePicture') : t('translation.beforeUnlock.takeAPicture')}
                </Button>
            </div>
            {base64Image ? (
                <img src={base64Image} alt="Captured" style={{ maxWidth: '100%' }} />
            ) : (
                <img src="/placeholder.png" alt="Upload" className="image-preview" />
            )}
        </div>
    );
};

export default ImageUpload;
