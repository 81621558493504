import React, { useEffect, useMemo } from 'react';

export default function IframeWrapper() {
    const removeURLParameter = (url: string, parameter: string) => {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0; ) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    };

    useEffect(() => {
        const iframeWindow = document.getElementById('gokaya-iframe') as any;
        window.addEventListener('message', (message) => {
            if (message.data.command === 'gokaya-HideBookingWindow') {
                if (iframeWindow) {
                    iframeWindow.style.display = 'none';
                }

                document.body.style.overflow = '';

                // Remove query-parameters coming from acceptUrl
                let newUrl = removeURLParameter(window.location.toString(), 'orderId');
                newUrl = removeURLParameter(newUrl, 'showConfirm');
                newUrl = removeURLParameter(newUrl, 'unlockOrder');
                newUrl = removeURLParameter(newUrl, 'returnBooking');
                newUrl = removeURLParameter(newUrl, 'status');
                newUrl = removeURLParameter(newUrl, 'siteId');
                window.history.pushState({}, document.title, newUrl);
            }

            // if (message.data.command === 'gokaya-RedirectToStripe') {
            //     if (message.data.detail.url) {
            //         window.location.href = message.data.detail.url;
            //     }
            // }
        });

        return () => {
            window.removeEventListener('message', () => {});
        };
    }, []);

    const queryString = useMemo(() => {
        const params: any = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop: any) => searchParams.get(prop)
        });
        const orderId = params.orderId;
        let returnString = '';

        if (orderId) {
            returnString = '?orderId=' + orderId;
        }

        return returnString;
    }, []);

    return (
        <iframe
            title="Gokaya booking"
            id="gokaya-iframe"
            className="gokaya-iframe"
            src={(process.env.REACT_APP_HOST_URL ?? '') + `/iframeContent.html${queryString}`}
            allow="payment"
            style={{
                width: '100%',
                height: '100%',
                top: '0px',
                left: '0',
                background: 'none',
                zIndex: 2147483647,
                position: 'fixed',
                border: 'none',
                display: 'none'
            }}></iframe>
    );
}
