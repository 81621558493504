/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import { BookingReceipt, Site } from '../product/interfaces';
import ProductSummary from '../product/ProductSummary';
import Marker from '../elements/Marker';
import { CheckoutDetails } from './CheckoutDetails';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import EventSummary from '../event/EventSummary';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import countries from '../../data/countriesData.json';

interface ReceiptField {
    label: string;
    value: string | undefined;
    order: number;
}

interface Props {
    receipt: BookingReceipt;
    currencyISO: string;
}

const OrderConfirm = ({ receipt, currencyISO }: Props) => {
    const [receiptFields, setReceiptFields] = useState<ReceiptField[]>([]);

    const formatDate = (date: string) => {
        return moment(date).format(receipt.site.account.timeSettingAmPm ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD HH:mm');
    };
    const { t } = useTranslation();

    const selectedEvents = useMemo(() => {
        return receipt.events?.flatMap((event) => event.bookableEvents || []) || [];
    }, [receipt]);

    useEffect(() => {
        if (!receipt.customer) {
            return;
        }

        const displayCountry = (() => {
            // Find the country object
            const selectedCountry = countries.countriesData.find((country) => country.key === receipt.customer.country);

            if (selectedCountry) {
                const currentLanguageKey = getCurrentLanguageFromLocalStorage();

                // Return the country name based on the current language
                const countryName = currentLanguageKey === 'sv' ? selectedCountry.labelSv : selectedCountry.labelEn;
                return countryName;
            }

            return '';
        })(); // This IIFE is immediately executed

        const fields: ReceiptField[] = [
            { label: 'name', value: `${receipt.customer.firstName} ${receipt.customer.lastName}`, order: receipt.site.cartSettings?.name.order || 0 },
            { label: 'email', value: receipt.customer.email, order: receipt.site.cartSettings?.email.order || 1 },
            { label: 'phone', value: receipt.customer.phoneNumber, order: receipt.site.cartSettings?.phone.order || 2 },
            { label: 'address', value: receipt.customer.address, order: receipt.site.cartSettings?.address.order || 3 },
            { label: 'country', value: displayCountry, order: receipt.site.cartSettings?.country.order || 4 },
            { label: 'city', value: receipt.customer.city, order: receipt.site.cartSettings?.city.order || 5 },
            { label: 'zipCode', value: receipt.customer.zipCode, order: receipt.site.cartSettings?.zipCode.order || 6 }
        ];

        // Only include fields where 'active' is not set to false
        const activeFields = fields.filter((field) => {
            const setting = receipt.site.cartSettings?.[field.label];
            return setting?.active !== false;
        });

        const fieldsInOrder = activeFields.sort((a, b) => a.order - b.order);

        setReceiptFields(fieldsInOrder);
    }, [receipt.customer, receipt.site.cartSettings]);

    const initialized = useRef(false);
    useEffect(() => {
        if (!initialized.current && receipt && receipt.site.account.measurementId) {
            ReactGA.initialize(receipt.site.account.measurementId);
            initialized.current = true;
        }
    }, [receipt]);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Order Confirmation Page'
            });
        }
    }, []);

    useEffect(() => {
        if (typeof window.gtag === 'function' && receipt.products) {
            const itemsForGTM = receipt.products.map((product) => ({
                item_name: typeof product.name === 'string' ? product.name : product.name.en,
                price: product.cost,
                quantity: product.quantity
            }));
            const ecommerceDetail = {
                transaction_id: receipt.id,
                affiliation: receipt.site.name,
                value: receipt.totalCost,
                currency: currencyISO.trim(),
                items: itemsForGTM
            };
            window.gtag('event', 'purchase', ecommerceDetail);
        }
    }, [receipt, currencyISO]);

    return (
        <Box sx={{ p: 2, position: 'relative' }}>
            <Typography variant="h4" color="black" sx={{ pb: 2, fontWeight: 500 }}>
                {t('translation.orderConfirmation.title')} {receipt.id}
            </Typography>
            <Box sx={{ pb: 1 }}>
                <Typography variant="body2">
                    {t('translation.orderConfirmation.pickUp')} {formatDate(receipt.startDate)}
                </Typography>
                <Typography variant="body2">
                    {t('translation.orderConfirmation.leaveBack')} {formatDate(receipt.endDate)}
                </Typography>
            </Box>
            <Box sx={{ pb: 1 }}>
                <Typography variant="h5" color="black" fontWeight="500" sx={{ mb: 1, mt: 1 }}>
                    {t('translation.orderConfirmation.customerDetails')}
                </Typography>
                {receiptFields.map((field, index) => {
                    return (
                        <Typography key={index} variant="body2" sx={{ lineHeight: 1.7 }}>
                            {t(`translation.orderConfirmation.${field.label}`)} {field.value}
                        </Typography>
                    );
                })}
            </Box>

            {receipt.pincodeData && receipt.pincodeData.length > 0 && <CheckoutDetails pinCodeData={receipt.pincodeData} />}

            <EventSummary
                selectedEvents={selectedEvents}
                availableEvents={receipt.events ?? []}
                currencyISO={currencyISO}
                disableEdit
                timeSettingAmPm={receipt?.site?.account?.timeSettingAmPm ?? false}
            />
            <ProductSummary selectedProducts={receipt.products ?? []} disableEdit currencyISO={currencyISO} />

            <Box sx={{ mb: 2 }}>
                {receipt.totalDiscount > 0 && (
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>
                            {t('translation.orderConfirmation.discount')}
                        </Typography>
                        <Typography sx={{ textAlign: 'right', color: '#f20000' }}>
                            {receipt.totalDiscount}
                            {currencyISO}
                        </Typography>
                    </Box>
                )}

                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>{t('translation.orderConfirmation.vat')}</Typography>
                    <Typography sx={{ textAlign: 'right' }}>
                        {receipt.totalVAT}
                        {currencyISO}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>{t('translation.orderConfirmation.total')}</Typography>
                    <Typography sx={{ textAlign: 'right' }}>
                        {receipt.totalCost}
                        {currencyISO}
                    </Typography>
                </Box>
            </Box>
            {receipt.site.textBookingConrimationEmail && (
                <Box sx={{ mb: 2 }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: translate(receipt.site.textThankyouPage, getCurrentLanguageFromLocalStorage())
                        }}
                    />
                </Box>
            )}
            <Box sx={{ pb: 2 }}>
                <Typography variant="h5" color="black" fontWeight="500" sx={{ mb: 1, mt: 1 }}>
                    {t('translation.orderConfirmation.findHere')}
                </Typography>
                <Typography variant="body2">{receipt.site.name}</Typography>
                <Typography variant="body2">{receipt.site.address}</Typography>
                <Typography variant="body2">
                    {receipt.site.city} {receipt.site.zipCode}
                </Typography>
            </Box>
            <Box sx={{ pb: 2 }}>
                <Box sx={{ height: '300px' }}>
                    <GoogleMapReact
                        defaultCenter={{
                            lat: Number(receipt.site.latitude),
                            lng: Number(receipt.site.longitude)
                        }}
                        defaultZoom={11}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
                            libraries: ['places']
                        }}>
                        <Marker lat={receipt.site.latitude} lng={receipt.site.longitude} />
                    </GoogleMapReact>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderConfirm;
